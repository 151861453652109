

  .sch-main-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    height: fit-content;
    min-height: 90vh;
  }

  .sch-container{
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    /* background-color: black; */
    /* background: transparent; */
    backdrop-filter: blur(8px);
    padding: 2rem;
    border-radius: 15px;
box-shadow: 1px 1px 24px 4px #ffffff ;

    
    
  }
.timeline{
  position:relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.timeline:before{
  position:absolute;
  content:'';
  width:4px;
  height:100%;
background: rgb(138,145,150);
background: -moz-linear-gradient(left, rgba(138,145,150,1) 0%, rgba(122,130,136,1) 60%, rgba(98,105,109,1) 100%);
background: -webkit-linear-gradient(left, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(98,105,109,1) 100%);
background: linear-gradient(to right, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(98,105,109,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#62696d',GradientType=1 );
  left:14px;
  top:5px;
  border-radius:4px;
}

.timeline-month{
  position:relative;
  padding:4px 15px 4px 35px;
  background-color:#444950;
  display:inline-block;
  width:auto;
  border-radius:40px;
  border:1px solid #17191B;
  border-right-color:black;
  margin-bottom:15px;
}

.timeline-month span{
  position:absolute;
  top:-1px;
  left:calc(100% - 10px);
  z-index:-1;
  white-space:nowrap;
  display:inline-block;
  background-color:#111;
  padding:4px 10px 4px 20px;
  border-top-right-radius:40px;
  border-bottom-right-radius:40px;
  border:1px solid black;
  box-sizing:border-box;
}

.timeline-month:before{
  position:absolute;
  content:'';
  width:20px;
  height:20px;
  background: rgb(138,145,150);
  background: -moz-linear-gradient(top, rgba(138,145,150,1) 0%, rgba(122,130,136,1) 60%, rgba(112,120,125,1) 100%);
  background: -webkit-linear-gradient(top, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(112,120,125,1) 100%);
  background: linear-gradient(to bottom, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(112,120,125,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#70787d',GradientType=0 );
  border-radius:100%;
  border:1px solid #17191B;
  left:5px;
  
}

.timeline-section{
  padding-left:35px;
  display:block;
  position:relative;
  margin-bottom:30px;
}
.timeline-section:last-child{
  margin-bottom: 0;
}
.timeline-date{
  margin-bottom:5px;
  padding:2px 15px;
  background:linear-gradient(#74cae3, #337c92 60%, #337c92);
  position:relative;
  width: fit-content;
  border-radius:20px;
  border:1px solid #17191B;
  color:#fff;
  text-shadow:1px 1px 1px rgba(0,0,0,0.3);
}
.timeline-section:before{
  content:'';
  position:absolute;
  width:30px;
  height:1px;
  background-color:#444950;
  top:12px;
  left:20px;
}

.timeline-section:after{
  content:'';
  position:absolute;
  width:10px;
  height:10px;
  background:linear-gradient(to bottom, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(112,120,125,1) 100%);
  top:7px;
  left:11px;
  border:1px solid #17191B;
  border-radius:100%;
}

.timeline-section .col-sm-4{
  margin-bottom:15px;

}

.col-sm-4{
  
}
.timeline-box{
  position:relative;
  
 background-color:#0f61d4;
  border-radius:15px;
  border-top-left-radius:0px;
  border-bottom-right-radius:0px;
  border:1px solid #17191B;
  transition:all 0.3s ease;
  overflow:hidden;
}

.box-icon{
  position:absolute;
  right:5px;
  top:0px;
}

.box-title{
  padding:5px 15px;
  border-bottom: 1px solid #17191B;
  background-color: #444950;
}

.box-title i{
  margin-right:5px;
}

.box-content{
  padding:10px 15px;
  background-image: var(--bg-image);
 backdrop-filter:blur(18px); 
 color: white;
}
.box-content strong{
  color:#cfcfcf;
  font-style:italic;
  margin-right:5px;
}

.box-item{
  margin-bottom:5px;
}

.box-footer{
 padding:5px 15px;
  border-top: 1px solid #17191B;
  background-color:#444950;
  text-align:right;
  font-style:italic;
}

