.events-page-info {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    text-align: left;
    padding: 1rem;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px rgb(255, 255, 255);


}
#bottom-box{
  min-height: 300px;
}
.events-page-bottom-row{
  display: flex;
  gap: 2rem;
}
.events-page-features-text{
  font-size: 25px;
}
.events-page-info h1{
  align-self: flex-start;
}
.events-page-info p{
  white-space: pre-line;
  text-align: left;
  width: 100%;
}
.events-page-countdown{
  display: flex;
  gap: 10px;
  border-radius: 15px;
  font-size: 30px;
  font-weight: 700;
}

.events-page-count-heading{
  font-size: 30px;
  font-weight: 700;
}
.events-page-main-count-heading{
  letter-spacing: 4px;
  font-size: 30px ;
  font-weight: 600;
  font-family: var(--font-family-space);
  text-align: center;
}
.events-page-info p {
    font-family: var(--font-family-space);
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    font-size: 20px;
    color: var(--color-white)



}


.events-page-bottom {
    padding: 1rem;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}


.events-page-row {
    display: flex;
    justify-content: center;
    padding: 1rem 0rem;
    z-index: -9;
}



.events-page-cards-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    /* padding: 1rem; */
    
    
  }
  .events-page-heading-text{
    font-family: var(--font-family-space);
    font-weight: 600;
    text-align: center;
  }
  .events-page-headline-text{
    font-size: 25px;
    text-align: center;
  }
  .events-page-vertical-card-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .events-page-vertical-card-bottom h1{
    font-size: 50px;
  }
  .events-page-horizontal-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    
    border-radius: 15px;
  }
  
  .events-page-horizontal-card-left{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  }
  .events-page-horizontal-card-right{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 2rem; */
    font-size: 22px;
  }
  .events-page-horizontal-card-userimage{
    width: 100%;
    max-width: 300px;
    /* height: 300px; */
    /* border-radius: 50%; */
  }
  .events-page-info {
    position: relative;
    align-self: center;
    
}

.events-page-ellipse-img {
  position: relative;
  width: 35%;
  
  border-radius: 100%;
}

.events-page-ellipse {
  padding: 0rem 1rem;
  
  display: flex;
  justify-content: space-around;
}

.director0,
.events-page-name-text0,
.events-page-ace {
  
  position: relative;
  justify-content: center;
  line-height: 90%;
  text-transform: capitalize;
}

.events-page-ace {
  font-size: 20px;
  font-family: var(--font-source-sans-pro);
  font-weight: 500;
  color: var(--color-dimgray-200);
}
.events-page-main{
  padding: 1rem;
  display: flex;
  width: 100%;
  gap: 2rem;
  min-height: calc(100vh - 75px);
  /* justify-content: space-; */
  flex-direction: column;
}

.events-page-event-button {
  border-radius: 5px;
  background-image: var(--bg-image);
  box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
  width: 14vw;
  height: 50px;
  cursor: pointer;
  color: var(--color-white);
  border: 0px;
  font-size: medium;
  font-weight: 600;
  font-family: var(--font-family-space);
  border: 0px;
  text-align: center;
  
  margin-top: 2em;
}

.events-page-event-button:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.events-page-event-button:active,
.events-page-event-button:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}




.events-page-App {
    padding: 2rem 0rem;
}



@media screen and (min-width:769px) and (max-width:1000px) {

  .events-page-horizontal-card {
    /* width: 100%;  */
    width: 70%;
    margin: auto;
    height: auto; 
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  .events-page-event-button {
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    width: 200px;
    height: 50px;
    cursor: pointer;
    color: var(--color-white);
    border: 0px;
    font-size: medium;
    font-weight: 600;
    font-family: var(--font-family-space);
    border: 0px;
    text-align: center;
    margin-top: 2em;
}
.events-page-horizontal-card-left,
    .events-page-horizontal-card-right{
      font-size: 25px;
    }
  
}


@media screen and (min-width:450px) and (max-width: 768px) {
    .events-page-cards-row {
      flex-direction: column; 
    }
  
    .events-page-horizontal-card {
      /* width: 100%;  */
      /* width: 70%; */
      /* margin: auto; */
      /* height: auto;  */
      padding: 2rem;
      display: flex;
      flex-direction: column;
    }
  
    .events-page-horizontal-card-left,
    .events-page-horizontal-card-right {
      width: 100%; 
      text-align: center;
      font-size: 20px;
    }
  
    .events-page-horizontal-card-userimage {
      width: 200px;
      height: 200px; 
      margin: auto;
    }
  
    .events-page-event-button {
      width: 100%;
    }
  
    .events-page-info p {
      font-size: 16px; 
    }
    .events-page-event-button {
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
      width: 200px;
      height: 50px;
      cursor: pointer;
      color: var(--color-white);
      border: 0px;
      font-size: medium;
      font-weight: 600;
      font-family: var(--font-family-space);
      border: 0px;
      text-align: center;
      margin: auto;
      margin-top: 2em;
  }
  
  }

  @media  screen and (min-width:400px) and (max-width:450px) {
    .events-page-horizontal-card {
      /* width: 100%;  */
      /* width: 70%; */
      /* margin: auto; */
      /* height: auto;  */
      padding: 2rem;
      display: flex;
      flex-direction: column;
    }
    .events-page-horizontal-card-userimage {
      width: 140px;
      height: 140px; 
      margin: auto;
    }
    .events-page-event-button {
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
      width: 8em;
      height: 50px;
      cursor: pointer;
      color: var(--color-white);
      border: 0px;
      font-size: medium;
      font-weight: 600;
      font-family: var(--font-family-space);
      border: 0px;
      text-align: center;
      margin: auto;
      margin-top: 2em;
  }
  }



@media (max-width: 400px) {
.events-page-cards-row{
    scale: 0.9;
}
.events-page-horizontal-card-userimage {
    width: 150px;
    height: 150px;
  }
  .events-page-event-button {
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    width: 10em;
    height: 50px;
    cursor: pointer;
    color: var(--color-white);
    border: 0px;
    font-size: medium;
    font-weight: 600;
    font-family: var(--font-family-space);
    border: 0px;
    text-align: center;
    margin: auto;
}
.events-page-horizontal-card-right{
  /* width: 100%; */
  text-align: center;
}
.events-page-horizontal-card {
  width: 100%; 
  /* width: 50%; */
  /* margin: auto; */
  /* height: auto;  */
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
}
@media (max-width: 350px) {
    .events-page-cards-row{
        scale: 0.8;
    }
    .events-page-event-button {
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
      width: 10em;
      height: 50px;
      cursor: pointer;
      color: var(--color-white);
      border: 0px;
      font-size: small;
      font-weight: 600;
      font-family: var(--font-family-space);
      border: 0px;
      text-align: center;
      margin: auto;
  }
  .events-page-horizontal-card-right{
    /* width: 100%; */
    text-align: center;
  }
  .events-page-horizontal-card {
    width: 100%; 
    /* width: 90vw; */
    /* margin: auto; */
    /* height: 22em;  */
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  .events-page-horizontal-card-userimage {
    width: 12em;
    height: 13em;
  }
    }
  