.home-main{
    height: calc(100vh - 75px);
    display: flex;
    
    align-items: center;
    justify-content: space-around;
}
.home-core{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 3rem;
    
}
.home-hero-skill-icon{
    height: 25px;
}
.home-hero-name{
    color: var(--fg-color);
    font-size: 50px;
}
.home-hero-skills{
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
}
.home-hero-img{
    height: 350px;
    border-radius: 100%;
    border: 5px solid var(--fg-color);
}
.home-hero-span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    justify-content: center;
}
.home-hero-span h1{
    font-family: var(--font-family-montserrat);
    font-size: 50px;
}
#designation{
    font-size: 40px;
    color: gray;
}
#designation2{
    font-size: 25px;
    color: gray;
}
















.main {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.up {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}
.down {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}
.social-card{
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: darkslategray;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
}
.card1 {
    border-radius: 90px 5px 5px 5px;
}
.card2 {
    border-radius: 5px 90px 5px 5px;
}
.card3 {
    border-radius: 5px 5px 5px 90px;
}
.card4 {
    border-radius: 5px 5px 90px 5px;
}
svg{
    fill: white;
}
.card4 svg {
    margin-top: -.9em;
    margin-left: -1.2em;
}
.card1 svg{
    margin-top: .9em;
    margin-left: 1.2em;
}
.card3 svg{
    margin-top: -.9em;
    margin-left: 1.2em;
}
.card2 svg{
    margin-top: .9em;
    margin-right: 1.2em;
}
.card:hover{
    cursor: pointer;
    scale: 1.1;
    fill: white;
    
}
.card1:hover {
    cursor: pointer;
    background-color: #1877f2;
    box-shadow: 0 0 5px 2px white;
}
.card2:hover{
    background-color: #cc39a4;
    cursor: pointer;
    box-shadow: 0 0 5px 2px white;
}
.card3:hover{
    background-color: black;
    cursor: pointer;
    box-shadow: 0 0 5px 2px white;
}
.card4:hover {
    background-color: #FF0004;
    cursor: pointer;
    box-shadow: 0 0 5px 2px white;
}


@media (max-width: 1250px) {
    .home-hero-img{
        height: 200px;
    }
    .home-main{
        min-height: calc(100vh - 75px);
        /* height: fit-content; */
        padding: 2rem 0;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .home-hero-span h1,.home-hero-span h2{
        text-align: center;
    }
    .home-hero-span{
        align-items: center;
        justify-content: center;
    }
    .home-core{
        height: fit-content;
        flex-direction: column;
    }
    .home-core h1{
        font-size: 30px;
    }
    #designation,#designation2{
        font-size: 25px;
    }
    
    
  }

@media (max-width: 800px) {
    .social-card{
        width: 60px;
        height: 60px;
    }
}

