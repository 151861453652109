
.events-button{
    border-radius: 5px;
    
    background-color: transparent;
    box-shadow: 0px 0px 18px 0px var(--fg-color);
    width: 100%;
    padding: 0 1rem;
    min-width: 125px;
    height: 50px;
    cursor: pointer;
    color: var(--color-white);
    
    border:2px solid var(--fg-color);
    font-family: var(--font-family-space);
    text-align: center;
    margin: 0;
    transition: 0.5s ease;
}
.events-github-button{
  height: 25px;
}
.events-buttons{
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 100%;
  
  /* background-color: red; */

}
.events-button:hover{
    text-shadow: 0px 0px 10px var(--glow-2);
    background-color: var(--fg-color);
}
.events-button:active{
  box-shadow: 2px 2px 6px var(--fg-color), -2px -2px 6px var(--fg-color);
  transform: scale(0.events-97);
  transition: 0.events-25s;
}
.events-vertical-name-desc{
  text-align: center;
  color: slategray;
}
.register_open_date_time{
  font-size: 18px;
  font-weight: 800;
  text-align: center;
}

.events-main{
  padding: 2rem 0;
  display: flex;
  min-height: calc(100vh - 75px);
  justify-content: space-around;
  flex-direction:column;
  gap: 3rem;
  z-index: -10;
  
  
}
.events-core{
  display: flex;
  flex-wrap: wrap;
  
  flex-direction: column;
  justify-content:center;
  gap: 3rem;
  
  
  
}
.events-cards-row{
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;  

  
  
  
}
#project-type{
  background-color: var(--fg-color);
  color: var(--bg-color);
}
.events-heading-text{
  font-family: var(--font-family-akira);
  font-weight: 600;
  width: 100%;
  
  
  padding: 1rem 0rem;
  text-align: center;
}


.events-button{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
}

.events-vertical-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
  max-width: 500px;
  width: 350px;
  min-height: 250px;
  /* height: 250px; */
  backdrop-filter: blur(15px);
  gap: 2rem;
  box-shadow: 0px 0px 18px 0px var(--fg-color);
  border-radius: 15px;
  padding: 1rem;
  
}

.events-vertical-card-userimage{
  width: 250px;
  height: 250px;
}
.events-vertical-card-top{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.events-vertical-card-bottom{
  display: flex;
  flex-direction: column;
  gap:1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.events-vertical-name-text{
  font-size: xx-large;
  font-family: var(--font-family-akira);
  font-weight: 800;
  text-align: center;
  color: var(--fg-color);
  text-wrap: wrap;
  text-transform: uppercase;
}
.events-vertical-info-text{
  font-size: medium;
  font-weight: 600;
}

@media (max-width: 768px) {
  

  .events-vertical-card {
    width: auto;
    height: auto;
    min-width: 200px;
  }

  .events-vertical-card-userimage {
    width: 150px;
    height: 150px;
  }

  .events-vertical-card-top {
    margin-bottom: 1rem;
  }
}



@media (max-width: 768px) {
  .events-main {
    padding: 0rem;
  }

  .events-cards-row,.events-core {
    gap: 2rem; 
  }

  .events-heading-text {
    font-size: 1.5rem; 
  }

  .events-vertical-card {
    /* width: calc(50% - 10px);  */
    height: auto;
    padding: 0.5rem;
  }

  .events-vertical-card-userimage {
    width: 100px;
    height: 100px;
  }

  .events-vertical-card-bottom {
    margin-bottom: 1rem;
  }

  .events-vertical-name-text {
    font-size: large; 
    
  }

  .events-vertical-info-text {
    font-size: small; 

  }

  .events-button {
    font-size: small; 
    width: 100px; 
    height: 40px; 
    margin: 1rem 0; 
  }
}

@media (max-width: 480px) {
  .events-vertical-card {
    width: 100%; 
  }

  .events-vertical-card-userimage {
    width: 80px;
    height: 80px;
  }
}

