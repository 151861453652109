.menu-text{
    font-family: var(--font-family-akira);
}
/* Style the dropdown container */

.menu-menu_container p {
    /* color: var(--font-color); */
    
    font-family: var(--font-family-akira);
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
}
.menu-txt:hover{
    /* text-shadow: 0px 0px 10px var(--glow-2); */
    color: var(--fg-color);
}
.menu-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.menu-menu_container {
    display: flex;
    padding: 0.2rem;
    justify-content: flex-start;
    /* background-image: var(--bg-image); */
    background-color: black;
    align-items: flex-start;
    flex-direction: column;
    z-index: 99999999999;
    text-align: left;
    position: absolute;
    top: 30px;
    width: 90%;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(255, 255, 255);
}


.menu-menu_container-links-sign {
    display: none;
}
@media screen and (max-width:1350px) {
    
}