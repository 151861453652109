.footer-copyright-para{
    font-family: var(--font-family-space);
    padding: 0;
    margin: 0;
    gap: 1rem;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    /* display: flex; */
}
.footer-main-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.footer-main{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 1rem;
    backdrop-filter: blur(6px);
    bottom: 0;
    width: 100%;
}
.footer-menu-gap{
    gap: 2rem;
}
.footer-hr{
    width: 100%;
}
.footer-header-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.footer-core-container{
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;

}
.footer-head-mirage,.footer-head-large,.footer-para{
    text-align: center;
    font-family: var(--font-family-space);
}
.footer-head-large {
    font-size: 2rem;
    letter-spacing: 2px;
    word-spacing: 2px;
    width: 100%;
    color: var(--fg-color);
    font-family: var(--font-family-akira);
}
.footer-inditech-logo{
    height: 50px;
}
.footer-copyright-para span {
    color: var(--fg-color);
}
.footer-para{
    font-size: 20px;
    width: 100%;
    
}

.footer-contact-btn{
display: flex;
justify-content: center;
align-items: center;
margin:auto;
height: 2rem;
width: 7rem;
margin-top: 2rem;
flex-wrap: wrap;
font-weight: 1000;

margin-bottom: 2rem;
margin-top: 2rem;
}

.footerlogo{
    height: 2em;
}
.footerlogo:hover{
    color: var(--fg-color);
}
.footer-signin-logo-container{
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    
    align-items: center;
    justify-content: flex-end;
}
.footersignin{
    height: 10px;
}
.footer-navbar{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    font-weight: 600;
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
}
.footer-navbar-main{
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}
.footericons{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}
.footer-meny-meny-main{
    display: flex;
    gap: 20px;

}

.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.right-footer-wing{
    margin-top: 1.8rem;
}
.footer-para-2{
    word-spacing: 4px;
}
.emailbtn{
    backdrop-filter: blur(5px);
    background-color:rgba(0, 0, 0, 0.8) ;
    border: 1px solid black;  
    width:16vw; 
height: 4vh;
padding: 10px;
text-align: center;
/* margin-left: 40px; */
}
.footer-map-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 0.5rem;
    width: 100%;
}
.footer-map{
    border: 1px solid;
    border-radius: 15px;
    width:100%;
    height:15rem;
    box-shadow: 0px 0px 8px 8px rgb(113, 111, 111);
}


.footer-bottom-text{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}
/* Media queries for responsiveness */

@media only screen and (max-width:800px) {

    .footer-menu-gap{
        flex-direction: row;
    }
    .footer-bottom-text{
        justify-content: center;
    }
}
@media only screen and (max-width:1100px) {

    .footer-core-container{
        justify-content: center;
        gap: 1rem;
    }
}


@media only screen and (max-width:400px) {

    .footer-head-large{
        font-size: 2.0rem;
    }
}


@media only screen and (max-width:300px) {
    .footer-header-container{
        display: none;
    }
}